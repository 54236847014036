import React from "react";
import Layout from "../components/layout";

const NotFoundPage = () => (
  <Layout>
    <div id="main">
      <section className="main">
        <div className="spotlight">
          <div className="content">
            <header className="major">
              <h2>404 - NOT FOUND</h2>
            </header>
            <p>You just hit a route that doesn&#39;t exist...</p>
          </div>
        </div>
      </section>
    </div>
  </Layout>
);

export default NotFoundPage;
